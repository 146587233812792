.form-container {
    padding: 8px;
    margin: 8px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    position: relative;
    padding-left: 48px;
    position: relative;
}

.draggable-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 100%;
    background: #d3d3d355;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question-label {
    margin-top: 8px;
    font-style: normal;
    min-width: 50px;
    color: #111519;
    font-family: 'Mulish';
    font-weight: 700;
    font-size: '16px';
    line-height: '128%';
    letter-spacing: '0px';
}

.question-field {
    width: 100%;
    position: relative;
}

.question-error {
    font-size: 10px;
    font-weight: 500;
    color: #f55b4d;
    margin-left: 8px;
    margin-top: -4px;
}

.question-input {
    font-size: 12px;
    border-radius: 6px;
    background-color: rgb(228, 227, 232);
    font-family: Inter;
    font-weight: 400;
    font-style: normal;
    line-height: 1.4375em;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    padding: 4px 8px;
    width: 100%;
    --darkreader-inline-bgcolor: #262a2b;
    --darkreader-inline-color: rgba(232, 230, 227, 0.87);
    margin: 4px;
    margin-bottom: 0px;
    border: 1px solid;
    border-color: transparent;
    outline: none;
    box-sizing: border-box;
}

.question-input:hover {
    border-color: #02569D;
}

.question-input:focus {
    box-shadow: 0px 0px 4px #02569D;
    border-color: transparent;
}

input.question-input[type="number"] {
    width: 72px;
}

textarea.question-input {
    resize: none;
}

.field-wrapper {
    display: inline-flex;
    width: 100%;
    margin-top: 2px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.left {
    flex-grow: 1;
}

.right {
    width: 35%;
    margin-left: 16px;
}

.option-chips-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 8px;
}

.option-chip {
    margin-right: 8px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #02569D;
    color: #02569D;
    border-radius: 80px;
    display: inline-flex;
    align-items: center;
    transition: all 500ms ease-in-out;
    font-family: Inter;
}

.small-chip {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
}

.large-chip {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 13px;
}

.selected-chip {
    color: white;
    background-color: #02569D;
}

.option-chip-icon {
    margin-right: 4;
    pointer-events: none;
}